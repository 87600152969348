import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				PowerFlex Gym
			</title>
			<meta name={"description"} content={"Wir stärken Ihre Fitness"} />
			<meta property={"og:title"} content={"PowerFlex Gym"} />
			<meta property={"og:description"} content={"Wir stärken Ihre Fitness"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb620fdcae00021e48116/images/1-3.jpg?v=2024-07-11T12:43:06.324Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});